<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="close"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
            UPDATE REQUEST
        </v-card-title>
        <v-card-text>
            <h4 class="mx-2 mt-2">{{ partialDelivery.description }}</h4>
            <h4 class="mx-2 mb-4">Available Quantity: {{ available }}</h4>
            <v-form v-model="valid">
                <v-row no-gutters class="mt-2 mb-2">
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="partialDelivery.quantity"
                            prepend-icon="mdi-numeric-10-box-multiple-outline"
                            type="number"
                            class="px-2"
                            label="Quantity"
                            :rules="[validateQuantity, rules.required]"
                        />
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ attrs, on }">
                                <v-text-field
                                    v-model="partialDelivery.deliverDate"
                                    label="Deliver Date"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="px-2"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="partialDelivery.deliverDate"
                                :min="min"
                                @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row no-gutters class="my-4">
                    <v-textarea
                        v-model="partialDelivery.notes"
                        hide-details
                        rows="2"
                        label="Notes"
                        class="px-2"
                    />
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-spacer />
            <v-btn
                class="px-4 mx-2 mb-4"
                color="primary"
                rounded
                @click="update"
                :disabled="!valid"
                :loading="loading"
            >
                UPDATE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'

export default {
    name: 'EditPartialDeliveryRequest',
    props: {
        partialDelivery: {
            type: Object,
            required: true,
        },
        originalPartialDelivery: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        menu: false,
        bom: {},
        available: 0,
        rules: {
            required: v => !!v || 'The value is required',
        },
        min: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 10),
    }),
    computed: {
        validateQuantity() {
            return (
                !this.partialDelivery.quantity ||
                (this.partialDelivery.quantity > 0 &&
                    this.available >= this.partialDelivery.quantity) ||
                'This value cannot be assigned'
            )
        },
        partialDeliveryDiff() {
            return objDiff(this.originalPartialDelivery, this.partialDelivery)
        },
    },
    async mounted() {
        try {
            this.loading = true
            // we need to retrieve data from the BOM to validate the quantities available for delivery
            this.bom = await API.findBOMByCode(
                this.partialDelivery.bomCode,
                this.partialDelivery.bomId
            )
            // retrieve bom item
            const item = this.bom.items.find(
                item => item.id == this.partialDelivery.itemId
            )
            // To determine the maximum available quantity that can be changed,
            // subtract the cumulative partial quantities requested and the quantity
            // of the selected partial delivery from the remaining quantity.
            this.available =
                item.remaining - (item.requests - this.partialDelivery.quantity)
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async update() {
            try {
                this.loading = true
                const updatedRequest = await API.updatePartialDeliveryRequest({
                    workOrderId: this.partialDelivery.workOrderId,
                    bomId: this.partialDelivery.bomId,
                    itemId: this.partialDelivery.itemId,
                    id: this.partialDelivery.id,
                    ...this.partialDeliveryDiff,
                })
                this.$emit('replace', updatedRequest)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
